import styled from '@emotion/styled'

export const SubtotalWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Subtotal = styled.h3`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`

export const Price = styled.span``

export const Notice = styled.p`
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
  opacity: 0.7;
`

export const BuyButton = styled.button`
  background-color: #2ac0fb;
  color: white;
  width: 100%;
  text-decoration: none;
  font-size: 1rem;
  padding-right: 1.45rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: none;
  align-self: flex-end;
  transition: 0.2s;

  &:hover {
    background-color: RGBA(42, 192, 251, 0.8);
  }
`

export const IconWrapper = styled.span`
  z-index: 23;
  cursor: pointer;
`
