import React, { useContext } from 'react'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

import StoreContext from '../../context/StoreContext'
import LineItem from './LineItem'

import { BuyButton, Subtotal, SubtotalWrapper, Notice } from './styles'
import { calcuateGrossPrice } from '../../utils/helpers'

const Cart = () => {
  const { client, checkout, updateLineItem, countryCode } =
    useContext(StoreContext)
  const { t } = useTranslation()

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  const lineItems = checkout.lineItems
    ? checkout.lineItems.map(item => (
        <LineItem
          key={item.id.toString()}
          item={item}
          currencyCode={checkout.currencyCode}
          countryCode={countryCode}
          client={client}
          checkout={checkout}
          updateLineItem={updateLineItem}
        />
      ))
    : null

  const price = Intl.NumberFormat(undefined, {
    currency: checkout.currencyCode ? checkout.currencyCode : 'EUR',
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(
    parseFloat(
      checkout ? calcuateGrossPrice(checkout.subtotalPrice, countryCode) : 0
    )
  )

  return (
    <>
      {checkout && lineItems.length > 0 ? (
        <>
          {lineItems}
          <SubtotalWrapper>
            <Subtotal>
              <Trans>Subtotal</Trans>
            </Subtotal>
            <Subtotal>{price}</Subtotal>
          </SubtotalWrapper>
          <Notice>
            <Trans>DISCOUNT_INFO_TEXT</Trans>
          </Notice>
          <BuyButton onClick={handleCheckout} role="button">
            {t('Checkout')} →
          </BuyButton>
        </>
      ) : (
        <Notice>
          <Trans>EMPTY_CART_MESSAGE</Trans>
        </Notice>
      )}
    </>
  )
}

export default Cart
