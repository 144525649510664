import React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Cart from '../components/Cart'
import Page from '../templates/Page'
import { Spacer } from '../utils/styles'

const CartPage = () => {
  const { t } = useTranslation()
  return (
    <Page title={t('Cart')}>
      <Cart />
      <Spacer height="5rem" />
    </Page>
  )
}

export const query = graphql`
  query ($language: String!) {
    i18nLocales: allLocale(
      filter: { ns: { in: ["cart"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default CartPage
