import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-template-rows: 1fr;
  column-gap: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1pt solid #343535;
`

export const ProductImage = styled.img`
  width: 100%;
  height: auto;
`

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  align-content: flex-start;
`

export const ProductName = styled.p`
  margin-top: 0px;
  line-height: 1;
  font-size: 1.5rem;
  font-weight: 500;
`

export const LastRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Price = styled.span`
  align-self: flex-end;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`
